import React from 'react'
import { css } from '@emotion/css'

const menuButtonStyle = css`

    width: 20px;
    height: 10px;
    border: none;
    stroke-width: 1px;
    stroke-linecap: round;
    stroke: rgba(0, 0, 0, 0.25);
`
export const icons =
	{
		menu: (
			<svg className={menuButtonStyle}>
			<line x1={1} y1={1} x2={19} y2={1} />
<line x1={1} y1={5} x2={19} y2={5} />
<line x1={1} y1={9} x2={19} y2={9} />
</svg>)
	}