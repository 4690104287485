import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../app/store'

export type UIState = {
	showMenu: boolean
}
export const initialState: UIState = {
	showMenu: false
}
export const uiSlice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		toggleMenu: state => {
			state.showMenu = !state.showMenu
		}
	}
})

export const { toggleMenu } = uiSlice.actions

export const selectMenuShown = (state: RootState) => state.ui.showMenu