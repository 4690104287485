import React, {ReactNode} from "react";
import {css} from "@emotion/css";


const rowStyle = css`
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
`

type RowProps = {
    children: ReactNode
}
const Row = (props: RowProps) => {
    return (
        <div className={rowStyle}>
            {props.children}
        </div>
    );
}

export default Row;
