export const Rows = ['topRow', 'homeRow', 'bottomRow']
export const rowNames: { [Property in Row]: string } =
	{
		topRow: 'Top row',
		homeRow: 'Home row',
		bottomRow: 'Bottom row'
	}

export type Row = typeof Rows[number]

export type Selection = {
	[Property in Row]: number
}
export const selectedCodes = (selection: Selection) => {
	return [
		topRow.slice(0, selection.topRow),
		homeRow.slice(0, selection.homeRow),
		bottomRow.slice(0, selection.bottomRow)
	]
		.flat(2)
		.map(key => `Key${key}`)
}

export const emptySelection: Selection = {
	topRow: 0,
	homeRow: 0,
	bottomRow: 0,
}

export const defaultSelection: Selection = {
	topRow: 0,
	homeRow: 3,
	bottomRow: 0,
}



export const topRow: string[][] = [
	['R', 'U'],
	['E', 'I'],
	['W', 'O'],
	['Q', 'P'],
	['T', 'Y']
]

export const homeRow: string[][] = [
	['F', 'J'],
	['D', 'K'],
	['S', 'L'],
	['A'],
	['G', 'H']
]

export const bottomRow: string[][] = [
	['V', 'M'],
	['C'],
	['X'],
	['Z'],
	['B', 'N']
]