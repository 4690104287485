import key from './Key'
import { selectedCodes, Selection } from '../../../app/sets'
import { icons } from '../../../app/icons'
import { ReactNode } from 'react'

export type KeyMapping = { code: string, label: string }
export type Spacing = { size: number, icon?: ReactNode }

export type KeyMap = (Spacing | KeyMapping)[][]

export const hangulKeyMap = [
	[
		{size: 1},
		{size: 1},
		{size: 1},
		{size: 1},
		{size: 1},
		{size: 1},
		{size: 1},
		{size: 1},
		{size: 1},
		{size: 1},
		{size: 1},
		{size: 1},
		{size: 1},
		{size: 1},
		{size: 1}
	],
	[
		{size: 1.5},
		{ code: 'KeyQ', label: 'ㅂ' },
		{ code: 'KeyW', label: 'ㅈ' },
		{ code: 'KeyE', label: 'ㄷ' },
		{ code: 'KeyR', label: 'ㄱ' },
		{ code: 'KeyT', label: 'ㅅ' },
		{ code: 'KeyY', label: 'ㅛ' },
		{ code: 'KeyU', label: 'ㅕ' },
		{ code: 'KeyI', label: 'ㅑ' },
		{ code: 'KeyO', label: 'ㅐ' },
		{ code: 'KeyP', label: 'ㅔ' },
		{size: 1},
		{size: 1},
		{size: 1.5},
	],
	[
		{size: 1.7},
		{ code: 'KeyA', label: 'ㅁ' },
		{ code: 'KeyS', label: 'ㄴ' },
		{ code: 'KeyD', label: 'ㅇ' },
		{ code: 'KeyF', label: 'ㄹ' },
		{ code: 'KeyG', label: 'ㅎ' },
		{ code: 'KeyH', label: 'ㅗ' },
		{ code: 'KeyJ', label: 'ㅓ' },
		{ code: 'KeyK', label: 'ㅏ' },
		{ code: 'KeyL', label: 'ㅣ' },
		{size: 1},
		{size: 1},
		{size: 1},
		{size: 1.25},
	],
	[
		{size: 2.25},
		{ code: 'KeyZ', label: 'ㅋ' },
		{ code: 'KeyX', label: 'ㅌ' },
		{ code: 'KeyC', label: 'ㅊ' },
		{ code: 'KeyV', label: 'ㅍ' },
		{ code: 'KeyB', label: 'ㅠ' },
		{ code: 'KeyN', label: 'ㅜ' },
		{ code: 'KeyM', label: 'ㅡ' },
		{size: 1},
		{size: 1},
		{size: 1},
		{size: 2.75}
	],
	[
		{size: 1.25},
		{size: 1.25},
		{size: 1.25},
		{size: 6.25, icon: icons.menu},
		{size: 1.25},
		{size: 1.25},
		{size: 1.25},
		{size: 1.25},
	]
]

export const hangulMappings =
	hangulKeyMap.flatMap((row) =>
		row.flatMap((key) => {
			if (typeof key === 'number') {
				return []
			} else {
				return [key]
			}
		})
	)

export const availableLetters = (keymap: KeyMap) =>
	keymap.flatMap((row) =>
		row.flatMap((key) => {
			if ((key as Spacing).size !== undefined) {
				return []
			} else {
				return [(key as KeyMapping).label]
			}
		})
	)

export const subset = (of: KeyMap, selection: Selection): KeyMap => {
	const codes = selectedCodes(selection)
	return of.map(row =>
			row.map(mapping => {
				if ((mapping as Spacing).size !== undefined) {
					return mapping
				} else if (selectedCodes(selection).indexOf((mapping as KeyMapping).code) > -1) {
					return mapping
				} else {
					return {size: 1}
				}
			})
	)
}

const homeRowLeftShort = [
	'KeyA',
	'KeyS',
	'KeyD',
	'KeyF'
]

const homeRowRightShort = [
	'KeyJ',
	'KeyK',
	'KeyL'
]

const homeRowLeft = [
	'KeyA',
	'KeyS',
	'KeyD',
	'KeyF',
	'KeyG'
]