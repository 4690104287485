import React from 'react'
import { css, cx } from '@emotion/css'

const vStyle = css`
    width: 100%;
    height: 100%;
		display: flex;
		flex-direction: column;
`

type stackProps = {
	children: React.ReactNode | React.ReactNode[],
	className?:  string
}

const V = ({ children, className }: stackProps) => {
	return (
		<div className={cx(vStyle, className)}>
			{
				React
					.Children
					.toArray(children)
					.map((child, i) => child)
			}
		</div>
	)
}

export default V
