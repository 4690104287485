import React, { useEffect } from 'react'
import { css } from '@emotion/css'
import Expected from './components/exercise/Expected'
import Keyboard from './components/exercise/keyboard/Keyboard'
import { useAppDispatch, useAppSelector } from './app/hooks'
import { hangulMappings, KeyMapping } from './components/exercise/keyboard/KeyMap'
import Menu from './components/menu/Menu'
import V from './components/layout/V'
import { selectedCodes } from './app/sets'
import { selectEnabledKeys } from './components/exercise/exerciseSlice'
import { selectMenuShown } from './app/uiSlice'
import Stack from './components/layout/Stack'

const appStyle = css`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
`

const App = () => {

	const dispatch = useAppDispatch()
	const showMenu: boolean = useAppSelector(selectMenuShown)
	const handleKeyPress = (e: KeyboardEvent) => {
		if (!e.ctrlKey) {
			e.preventDefault()

			console.log(e.code)

			if (e.type === 'keyup' && e.code === 'Space') {
				dispatch({
					type: 'ui/toggleMenu'
				})
			} else {

				const keyEvent = {
					type: 'keyboard/event',
					payload: {
						code: e.code,
						state: e.type === 'keyup' ? 'up' : 'down'
					}
				}

				dispatch(keyEvent)

				if (e.type === 'keyup') {
					const mapping = hangulMappings.find(x => (x as KeyMapping).code === e.code)


					if (mapping) {
						dispatch({
							type: 'exercise/input',
							payload: (mapping as KeyMapping).label
						})
					}
				}
			}
		}
	}

	useEffect(() => {
		window.addEventListener('keydown', handleKeyPress)
		window.addEventListener('keyup', handleKeyPress)

		return () => {
			window.removeEventListener('keypress', handleKeyPress)
			window.removeEventListener('keyup', handleKeyPress)
		}
	})

	return (
		<Stack className={appStyle}>
			<V className={appStyle}>
				<Expected />
				<Keyboard />
			</V>
			<Menu />
			{/*{showMenu ? <Menu />: <> </>}*/}
		</Stack>
	)
}
export default App
