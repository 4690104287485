import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../app/store'

export type KeysState = {
	[Property in string]: boolean
}

type KeyEvent = {
	code: string,
	state: 'up' | 'down'
}

export type KeyboardState = {
	shift: boolean,
	keys: KeysState
}

export const initialState: KeyboardState = {
	shift: false,
	keys: {}
}
export const keyboardSlice = createSlice({
	name: 'keyboard',
	initialState,
	reducers: {
		event: (state, { payload }: PayloadAction<KeyEvent>) => {
			if (payload.code === 'ShiftLeft' || payload.code === 'ShiftRight') {
				state.shift = payload.state === 'down'
			} else {
				state.keys[payload.code] = payload.state === 'down'
			}
		}
	}
})

export const { event } = keyboardSlice.actions

export const selectKeys = (state: RootState) => state.keyboard.keys