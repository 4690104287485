import { css } from '@emotion/css'
import Key from './Key'
import { hangulKeyMap, KeyMapping, Spacing } from './KeyMap'
import Spacer from './Spacer'
import Row from './Row'
import { useAppSelector } from '../../../app/hooks'
import { selectEnabledKeys } from '../exerciseSlice'
import { selectKeys } from '../../keyboardSlice'
import { selectedCodes } from '../../../app/sets'

const keyboardStyle = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
`

const Keyboard = () => {

	const keys = useAppSelector(selectKeys)
	const enabledKeys: string[] = selectedCodes(useAppSelector(selectEnabledKeys))
	//TODO const correct = typed.length > 0 && expected[expected.length - 1][typed.length - 1] === typed[typed.length - 1]

	return (
		<div className={keyboardStyle}>
			{
				hangulKeyMap.map((row, y) =>
					<Row key={y}>{
						row.map((key, x) => {
							if ((key as Spacing).size !== undefined) {
								return (
									<Spacer
										key={x}
										size={(key as Spacing).size}
										icon={(key as Spacing).icon }
									/>
								)
							} else {
								return (
									<Key
										key={(key as KeyMapping).code}
										active={enabledKeys.indexOf((key as KeyMapping).code) >= 0}
										label={(key as KeyMapping).label}
										// label={key.code[key.code.length - 1]}
										pressed={keys[(key as KeyMapping).code] || false}
										nipple={y === 2 && (x === 4 || x === 7)}
									/>
								)
							}
						})
					}</Row>
				)
			}
		</div>
	)
}

export default Keyboard