import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { availableLetters, hangulKeyMap, subset } from './keyboard/KeyMap'
import { RootState } from '../../app/store'
import { defaultSelection, Selection } from '../../app/sets'


const generateSlice = (lettersToUse: string[]) =>
	Array(16)
		.fill(null)
		.map(_ => lettersToUse[Math.floor(Math.random() * lettersToUse.length)])

type ExerciseState = {
	expected: string[][],
	enabledKeys: Selection,
	typed: string
}

const initialState: ExerciseState = {
	expected: [
		generateSlice(availableLetters(subset(hangulKeyMap, defaultSelection))),
		generateSlice(availableLetters(subset(hangulKeyMap, defaultSelection)))
	],
	enabledKeys: defaultSelection,
	typed: "",
}

export const exerciseSlice = createSlice({
	name: 'exercise',
	initialState,
	reducers: {
		setSelection: (state, { payload }: PayloadAction<Selection>) => {
			state.enabledKeys = payload
		},
		refresh: state => {

			const lettersToUse =
				availableLetters(subset(hangulKeyMap, state.enabledKeys))

			state.typed = ""
			state.expected = [
				generateSlice(lettersToUse),
				generateSlice(lettersToUse)
			]
		},
		input: (state, action: PayloadAction<string>) => {
			state.typed += action.payload

			const lettersToUse =
				availableLetters(subset(hangulKeyMap, state.enabledKeys))


			if(state.typed.length + 16 >= state.expected.length * 16) {
				state.expected = [...state.expected, generateSlice(lettersToUse)]
			}
		}

	}
})
export const { setSelection, input } =  exerciseSlice.actions

export const selectExpected = (state: RootState) => state.exercise.expected
export const selectTyped = (state: RootState) => state.exercise.typed
export const selectEnabledKeys = (state: RootState) => state.exercise.enabledKeys