import React from 'react'
import { css, cx } from '@emotion/css'

const hStyle = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
`

type stackProps = {
	children: React.ReactNode | React.ReactNode[],
	className?: string
}

const H = ({ children, className }: stackProps) => {
	return (
		<div className={cx(hStyle, className)}>
			{
				React
					.Children
					.toArray(children)
					.map((child, i) =>
						child
					)
			}
		</div>
	)
}

export default H
