import React from 'react'
import { css, cx } from '@emotion/css'
import { Selection, Row, selectedCodes, rowNames, emptySelection } from '../../app/sets'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { selectEnabledKeys } from '../exercise/exerciseSlice'
import H from '../layout/H'
import { selectMenuShown } from '../../app/uiSlice'

type Group = {
	label: string
	children: MenuItem[]
}

type Leaf = {
	label: string,
	selection: Selection
}

type MenuItem = Group | Leaf
const group =
	(label: string, children: MenuItem[]) => {
		return {
			label: label,
			children: children
		}
	}

const item =
	(label: string, selection: Selection) => {
		return {
			label: label,
			selection: selection
		}
	}


const menu =
	group(
		'Exercises',
		[
			group(
				'By row',
				['homeRow', 'topRow', 'bottomRow'].map((row: Row) =>
					group(
						rowNames[row],
						[1, 2, 3, 4, 5].map(i => {
								let s = { ...emptySelection }
								s[row] = i
								return item(
									`${i} finger${i > 1 ? 's' : ''}`, { ...s }
								)
							}
						)
					)
				)
			),
			group('Combined',[
				item(
					'Home and top row',
					{
						topRow: 5,
						homeRow: 5,
						bottomRow: 0
					}
				),
				item(
					'All rows',
					{
						topRow: 5,
						homeRow: 5,
						bottomRow: 5
					}
				)
			])
		]
	)


const groupStyle = css`
    padding-left: 10px;
    font-family: "Namun Square Round";
`

const groupLabelStyle = css`
    line-height: 25px;
`

const leafStyle = css`
    padding-left: 15px;
    line-height: 25px;
`

const active = css`
    font-weight: bold;
`

const inactive = css`
`
const Menu = () => {

	const dispatch = useAppDispatch()

	const selection = useAppSelector(selectEnabledKeys)
	const showMenu: boolean = useAppSelector(selectMenuShown)

	const handleClick = (selection: Selection) => {
		dispatch({
			type: 'exercise/setSelection',
			payload: selection
		})

		dispatch({
			type: 'exercise/refresh'
		})
	}

	const menuStyle = css`
      width: 100%;
      height: 100%;
	`

	const menuContainerStyle = css`
      padding-top: 10px;
      width: ${showMenu ? 200 : 0}px;
      transition-duration: 0.125s;
      transition-timing-function: ease-out;

      height: 100%;
      overflow: hidden;
      background-color: rgb(255, 255, 255);
	`

	const coverStyle = css`
      width: 100%;
      height: 100%;
      background: ${
              showMenu ?
                      'linear-gradient(90deg, rgba(0, 0, 0, 15%) 0%, rgba(0, 0, 0, 10%) 10%, rgba(0, 0, 0, 10%) 100%)' :
                      'rgba(255, 255, 255, 0)'

      };
      transition-duration: 0.25s;
      transition-delay: 0.125s;
      transition-timing-function: ease-in;
	`

	const render = (menuItem: MenuItem, current: Selection, i: number = 0) => {
		if ((menuItem as Group).children !== undefined) {

			return (
				<ol key={i} className={groupStyle}>
					<li className={groupLabelStyle}>{menuItem.label}</li>
					<ol>
						{(menuItem as Group).children.map((child, j) => {
							return (
								render(child, current, j)
							)
						})}
					</ol>
				</ol>
			)
		} else {
			const leaf = (menuItem as Leaf)
			return (
				<li
					key={i}
					className={cx(
						leafStyle,
						JSON.stringify(leaf.selection) === JSON.stringify(current) ? active : inactive
					)}
					onClick={_ => handleClick(leaf.selection)}>
					{leaf.label}
				</li>
			)
		}
	}

	return (

		<H className={menuStyle}>
			<div className={menuContainerStyle}>{render(menu, selection)}</div>
			<div id={'cover'} className={coverStyle} />
		</H>
	)
}

export default Menu
