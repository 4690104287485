import React from 'react'
import { css, cx } from '@emotion/css'
import V from '../../layout/V'


const keyStyle = css`
    width: 50px;
    height: 34px;
    padding-top: 16px;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.75);
    text-align: center;
    vertical-align: center;
    font-family: "Namun Square Round";
    font-size: 18px;
		background: rgba(255, 255, 255, 100%);
`
const labelStyle = css`
    line-height: 20px;
`
const active = css`
		border: 1px solid rgba(0, 0, 0, 0.75);
    color: rgba(0, 0, 0, 0.75);
    stroke: rgba(0, 0, 0, 0.75);
`
const inactive = css`
		border: 1px solid rgba(0, 0, 0, 0.25);
    color: rgba(0, 0, 0, 0.25);
    stroke: rgba(0, 0, 0, 0.25);
`

const depressed = css`box-shadow: 0 0 1px 1px rgba(0, 0, 0, 25%);`
const pressed = css`box-shadow: 0 0 1px 1px rgba(0, 0, 0, 10%);`

const nippleStyle = css`
		line-height: unset;
    width: 4px;
    height: 2px;
    border: none;
		stroke-width: 1px;
		stroke-linecap: round;
		margin-bottom: 13px;
`
const noNippleStyle = css`
    line-height: unset;
    width: 4px;
    height: 2px;
		border: none;
		stroke: rgba(0, 0, 0, 0)
`

type KeyProps = {
	label: string,
	active: boolean,
	pressed: boolean,
	nipple: boolean
}

const Key = (props: KeyProps) => {


	return (
		<V className={
			cx(
				keyStyle,
				props.active ? active : inactive,
				props.pressed ? pressed : depressed
			)}>
			<span className={labelStyle}>{props.label}</span>
			<svg className={cx(

				props.active ? active : inactive,
				props.nipple ? nippleStyle : noNippleStyle
			)}>
				<line x1={1} y1={1} x2={3} y2={1} />
			</svg>
		</V>
	)
}

export default Key
