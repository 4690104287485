import { configureStore } from '@reduxjs/toolkit'
import { exerciseSlice } from '../components/exercise/exerciseSlice'
import { keyboardSlice } from '../components/keyboardSlice'
import { uiSlice } from './uiSlice'

export const store = configureStore({
	reducer: {
		exercise: exerciseSlice.reducer,
		keyboard: keyboardSlice.reducer,
		ui: uiSlice.reducer,
	}
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch