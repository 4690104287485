import React, { ReactNode } from 'react'
import { css, cx } from '@emotion/css'

const menuButtonStyle = css`

    width: 20px;
    height: 10px;
    border: none;
    stroke-width: 1px;
    stroke-linecap: round;
    stroke: rgba(0, 0, 0, 0.25);
`

type SpacerProps = {
	size: number,
	icon?: ReactNode
}

const Spacer = ({ size, icon }: SpacerProps) => {
	return (
		<div className={
			css`
          width: ${size * 50 + (size - 1) * 7}px;
          height: 50px;
          line-height: 50px;
          border-radius: 5px;
          text-align: center;
          vertical-align: center;

          background: rgba(255, 255, 255, 100%);
          box-shadow: 0 0 1px 1px rgba(0, 0, 0, 10%);
          border: 1px solid rgba(0, 0, 0, 15%);
			`
		}>
			{icon}
		</div>
	)
}

export default Spacer
