import React from 'react'
import { css, cx } from '@emotion/css'

const viewPortStyle = css`
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
`


type stackProps = {
	children: React.ReactNode | React.ReactNode[]
	className?: string,
	offset?: {
		x?: number,
		y?: number
	},
	subjectClassName?: string,
}

const ViewPort = (props: stackProps) => {

	const subjectStyle = css`
      width: 100%;
      height: 100%;
      position: absolute;
      left: ${props.offset?.x || 0}px;
      top: ${props.offset?.y || 0}px;
	`

	return (
		<div className={cx(viewPortStyle, props.className ?? '')}>
			<div className={cx(subjectStyle, props.subjectClassName ?? '')}>
				{
					React.Children.toArray(props.children).map((child, i) => {
						return (
							<div
								key={i}
							>{child}</div>
						)
					})
				}
			</div>
		</div>
	)
}

export default ViewPort