import React from 'react'
import { css, cx } from '@emotion/css'
import { useAppSelector } from '../../app/hooks'
import { selectExpected, selectTyped } from './exerciseSlice'
import V from '../layout/V'
import H from '../layout/H'
import Stack from '../layout/Stack'
import ViewPort from '../layout/ViewPort'

const expectedStyle = css`
    width: 850px;
    height: 120px;
		margin: 50px 0;
`

const animation = css`
    transition-duration: 0.125s;
    transition-timing-function: ease-in;
`

const rowsStyle = css`
    gap: 15px;
`

const passepartoutStyle = css`
    background: linear-gradient(0deg, rgba(255,255,255,100) 0%, rgba(0,255,255,0) 35%);
    //background: linear-gradient(0deg, rgba(191, 31, 31, 1) 0%, rgba(0,255,255,0) 35%);
		height: 100%;
		width: 100%;
`

const rowStyle = css`
    display: flex;
    gap: 15px;
    justify-content: center;
    width: 100%;
`

const chunkStyle = css`
    width: 136px;
    height: 38px;
    padding: 13px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.75);
`

const targetLetterStyle = css`
    font-family: "Namun Square Round";
    font-size: 28pt;
    font-weight: bold;
`

const correct = css`color: rgba(31, 191, 31, 100)`
const incorrect = css`color: rgba(191, 31, 31, 75)`
const todo = css`color: rgba(0, 0, 0, 75)`

const Expected = () => {

	const rows = useAppSelector(selectExpected)
	const typed = useAppSelector(selectTyped)

	return (
		<Stack className={expectedStyle}>
			<ViewPort subjectClassName={animation} offset={{y:-((rows.length - 2) * 81)}}>
			<V className={rowsStyle}>
				{
					rows.map((row, y) => {
						return (
							<H key={y} className={rowStyle}>
								{
									Array(Math.ceil(row.length / 4))
										.fill(null)
										.map((_, i) => 4 * i)
										.map((x, i) =>
											<div
												key={i}
												className={chunkStyle}
											>{
												row
													.slice(x, x + 4)
													.map((letter, j) => {
															const ij = (i * 4) + j
															const yij = (y * 16) + (i * 4) + j
															return (
																<span
																	key={j}
																	className={
																		cx(
																			targetLetterStyle,
																			(typed.length <= yij) ? todo : (typed.charAt(yij) === rows[y][ij] ? correct : incorrect)
																		)}
																>{letter}</span>
															)
														}
													)
											}</div>)
								}
							</H>
						)
					})
				}
			</V>
			</ViewPort>
			<div className={passepartoutStyle} />
		</Stack>
	)
}

export default Expected
