import React from 'react'
import { css, cx } from '@emotion/css'

const stackStyle = css`
    width: 100%;
    height: 100%;
		position: relative;
`

const layerStyle = css`
    width: 100%;
    height: 100%;
    position: absolute;
		top: 0;
		left: 0;
`

type stackProps = {
	children: React.ReactNode | React.ReactNode[]
	className?: string[] | string
}

const Stack = ({ children, className }: stackProps) => {
	const classNames: string[] = ((typeof className === "string" ? [className] : className) || [])
	return (
		<div className={cx(stackStyle, ...classNames)}>
			{
				React.Children.toArray(children).map((child, i) => {
					return (
						<div
							key={i}
							className={layerStyle}
						>{child}</div>
					)
				})
			}
		</div>
	)
}

export default Stack
